/* The Palette:
https://coolors.co/df5f5d-ffd65d-3dc77d-42b4ff-d2a6e8*/

$accent-color: #3cb169;
$button-color: $accent-color;

$sans-serif-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
  "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

// Accent Color Conformance.
button,
a {
  font-family: $sans-serif-family;

  color: $button-color;

  cursor: pointer;

  border-radius: 5px;

  transition: all 400ms;

  &:hover {
    $hover-filter: blur(10px) saturate(350%);
    // background-color: scale-color($color: $accent-color, $alpha: -90%);
    backdrop-filter: $hover-filter;
    -webkit-backdrop-filter: $hover-filter;
  }
}

.red {
  color: red;
  border-color: red;
}

// Nav bar
nav {
  display: flex;
  justify-content: center;

  opacity: 40%;
  &:hover,
  &:focus {
    opacity: 100%;
  }

  font-size: 16px;
  overflow: auto;
  width: 100%;
  /*position: absolute;
	top: 591px;*/
  grid-gap: 0;
  border-spacing: 0;
  padding: 0;
  margin: 0;
  // border-bottom: 1px solid rgba(173, 173, 173, 0.72);
  $back-filter: saturate(200%) blur(5px);
  -webkit-backdrop-filter: $back-filter;
  backdrop-filter: $back-filter;
  // background-color: rgba(255, 214, 93, 0.04);
  transition-duration: 0.5s;
  z-index: 10;

  filter: saturate(200%);

  mix-blend-mode: luminosity;

  /* The Magic! This is what makes it stay at the top */
  position: -webkit-sticky;
  position: sticky;

  top: 0;

  input {
    color: inherit;
    background-color: transparent;
    text-align: center;

    border: 0;
    outline: 0;
    background: transparent;
    padding-bottom: 3px;
    border-bottom: 1px solid scale-color($color: white, $alpha: -50%);

    transition: border-bottom 200ms;
    &:focus {
      border-bottom: 3px solid scale-color($color: $accent-color, $alpha: -0%);
    }

    &:invalid {
      border-bottom-color: red;
    }
  }
  form {
    padding: 10px;
  }
}

// Utilities
//* A horizontal stack
.flex {
  display: flex;
  gap: 0.5rem;

  @extend .centered;
}

.block {
  display: block;
  width: 100%;
}

.centered {
  text-align: center;
  align-items: center;
  justify-content: center;
}

// Custom styles
// These are styles that should be available everywhere
a,
h1 {
  text-decoration: none;

  .no-style {
    color: #000;

    // &:visited {
    //   color: #000;
    // }
  }
}

.outline-btn {
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  border-color: $button-color;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  color: $button-color;
  // cursor: default;
  display: inline-flex;
  font-family: $sans-serif-family;
  font-size: 14px;
  font-weight: bold;
  height: 36px;
  justify-content: center;
  margin: 8px 16px;
  outline-color: $button-color;
  overflow: hidden;
  padding: 0px 25px;

  position: relative;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;

  white-space: nowrap;
}
